import React from 'react';
import './Container.scss';

const Container = ({ children }) => (
    <main id="content_area" class="w-100">
        <div class="container container-sm">
            {children}
        </div>
    </main>
);

export default Container;