import React from 'react';
import './Album.scss';

const Album = ({ index, images, setIndex, close }) => {
    return (
        <div class={`poem_album_modal ${images.length ? 'active' : ''}`}>
            {
                images && images.length &&
                <div className="modal_container">
                    <div className="close" onClick={e => close()}>
                        <p>Loka glugga</p>
                    </div>
                    <div className="current_image">
                        {images[index].image && <img src={images[index].image.url} alt="" />}
                    </div>
                    <div className="albumContent">
                        <div className="credits">
                            <h2>{images[index].image.alt}</h2>
                            <div className="credit">
                                {images[index].credit}
                            </div>
                        </div>
                        <div className="thumbs">
                            {images.map((image, i) =>
                                (image.image.thumb) ?
                                    <div class="media_item" onClick={e => setIndex(i)}>
                                        <img src={image.image.thumb.url} srcset={`${image.image.thumb.url} 2x`} alt={image.image.alt} />
                                    </div>
                                    :
                                    <div></div>
                            )}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Album;