import React from "react";
import { Container } from "../components/UI";

const Contact = () => (
  <Container>
    <h1>Hafa Samband</h1>
    <form action="https://formspree.io/f/xgvwzvjp" method="POST">
      <input type="text" placeholder="Nafn" name="Nafn" />
      <input type="email" placeholder="Netfang" name="Netfang" />
      <textarea placeholder="Skilaboð" name="Skilaboð"></textarea>
      <input type="submit" value="Senda" />
    </form>
  </Container>
);

export default Contact;
