import React from "react";
import "./Banner.scss";

const Banner = ({ title, pretitle, img, imglarge }) => (
  <div class="banner">
    <img src={img} class="banner-image" srcset={imglarge} alt="banner" />

    <div class="banner_text w-100">
      <div class="container container-md">
        <h2>{pretitle}</h2>
        <h1>{title}</h1>
      </div>
    </div>
  </div>
);

export default Banner;
