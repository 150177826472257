import React from 'react';
import './SearchBar.scss';

import searchIcon from './src/search.png';
import searchIconLarge from './src/search.png';

const SearchBar = ({ value, setSearch }) => (
    <div class="search_filter w-100">
        <img src={searchIcon} class="search_icon" srcset={`${searchIconLarge} 2x`} alt="search icon" />
        <input value={value} onChange={e => setSearch(e.target.value)} type="text" placeholder="Leita að ljóðum..." />
        {/* <button type="button" class="btn btn-reset btn-blue">Leit</button> */}
    </div>
);

export default SearchBar;