import React from 'react';
import './YearNav.scss';
import Slider from "react-slick";

// import arrLeft from './src/arrow_left.png'
// import arrLeftLarge from './src/arrow_left@2x.png'
// import arrRight from './src/arrow_right.png'
// import arrRightLarge from './src/arrow_right.png'

const YearNav = ({ year, range, setYear }) => (
    <nav id="nav-post" class="post_navlist">
        {/* <button type="button" class="btn-reset btn-arrow arrow-left">
            <img src={arrLeft} srcset={`${arrLeftLarge} 2x`} alt="" />
        </button>
        <button type="button" class="btn-reset btn-arrow arrow-right">
            <img src={arrRight} srcset={`${arrRightLarge} 2x`} alt="" />
        </button> */}


        <Slider {...{
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1,
        }}>
            {
                range.map((y, i) =>
                    <div className={`year ${year === y ? 'active' : ''}`} key={i}>
                        <div className="century" onClick={e => setYear(y)}>{y}. <em>öld</em></div>
                    </div>
                )
            }
        </Slider>
    </nav>
);

export default YearNav;