import React from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';

const Header = ({ title, links }) => (
    <header id="header" class="header">
        <div class="container">
            <div class="d-flex jc-between ai-end w-100">
                <Link to={'/'}>
                    <div class="logo">{title}</div>
                </Link>
                <div class="header_links">
                    {
                        links.map(link => (
                            <Link to={link.path}>
                                <b>{link.title}</b>
                            </Link>
                        ))
                    }
                </div>
            </div>
        </div>
    </header>
);

export default Header;