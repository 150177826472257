import React, { useState } from 'react';
import './Poem.scss';

import titleWaveLeft from './src/title_wave_left.png'
import titleWaveLeftLarge from './src/title_wave_left@2x.png'
import titleWaveRight from './src/title_wave_right.png'
import titleWaveRightLarge from './src/title_wave_right@2x.png'

import playIcon from './src/play_icon.png';
import playIconLarge from './src/play_icon@2x.png';

// import facebookIcon from './src/facebook.png';
// import facebookIconLarge from './src/facebook@2x.png';
// import twitterIcon from './src/twitter.png';
// import twitterIconLarge from './src/twitter@2x.png';

const Poem = ({ poem, i, toggleAlbum }) => {
    const [audio, setaudio] = useState(false)

    return (
        <div class="post_item" key={i}>
            <div class="watermark_title">{poem.year}</div>
            {poem.first &&
                <div class="post_title">
                    <h3>
                        <img src={titleWaveLeft} class="wave_left" srcset={`${titleWaveLeftLarge} 2x`} alt="wave left" />
                        <span>{poem.year}</span>
                        <img src={titleWaveRight} class="wave_right" srcset={`${titleWaveRightLarge} 2x`} alt="wave left" />
                    </h3>
                </div>
            }
            {/* .post_title */}

            <div class="post_content d-flex jc-between">
                <div class="post_content_left">
                    {poem.body}
                </div>
                {/* .post_content_left */}
                <div class="post_content_right">
                    {poem.description}
                </div>
                {/* .post_content_right */}
            </div>
            {/* .post_content */}

            <div class="post_media d-flex flex-wrap">
                {
                    !!poem.images.length && poem.images.map((image, i) =>
                        (image.image.thumb) ?
                            <div class="media_item" onClick={e => toggleAlbum(i, poem.images)}>
                                <img src={image.image.thumb.url} srcset={`${image.image.thumb.url} 2x`} alt={image.image.alt} />
                            </div>
                            :
                            <div></div>
                    )
                }
            </div>
            {/* .post_media d-flex */}

            <div class="post_bottom d-flex ai-center jc-between">
                <div class="post_play">
                    {
                        poem.audio.url &&
                            (audio) ?
                            <audio controls autoPlay> <source src={poem.audio.url} /> Your browser does not support the audio element. </audio>
                            :
                            <button type="button" class="btn-reset" onClick={e => setaudio(true)}><img src={playIcon} srcset={`${playIconLarge} 2x`} alt="play" /> Spila hljóðbrot </button>
                    }
                </div>
                {/* .post_play */}
                {/* <div class="post_share">
                    <a href="#"><img src={facebookIcon} srcset={`${facebookIconLarge} 2x`} alt="twitter" /></a>
                    <a href="#"><img src={twitterIcon} srcset={`${twitterIconLarge} 2x`} alt="facebook" /></a>
                </div> */}
                {/* .post_share */}
            </div>
            {/* .post_bottom d-flex jc-between */}
        </div>
    )
};

export default Poem;