import React from 'react';
import { Container } from '../components/UI';

const About = () => (
    <Container>
        <h1>Um Annálsvert.is</h1>
        <p>Á þessari vefsíðu eru færð í rím ein eða fleiri annálsverð tíðindi hvers árs frá siðaskiptum þar til Ísland gekk í NATO.</p>
        <p>Auk þess fær hver öld umfjöllun á lokaári sínu og tuttugasta öldin við 1950.</p>
        <p>Síðuhöfundur ætlar sér hvorki sæti á skáldabekk né sagnfræðinga, en vonar að þegar rím  fellur saman við texta og myndir verði til ítarefni sem geri sögu tímabilsins auðlærðari og nái að tengjast fleiri greinum.</p>
        <br /><br />
        <p>Séra Einar Sigurðsson, Eydölum yrkir:</p>
        <p>Kvæðin hafa þann kost með sér</p>
        <p>þau kennast betur og lærast ger,</p>
        <p>en málið laust úr minni fer.</p>
        <br /><br />
        <p>Þessi er tilgangur síðunnar. Þarf þá ekki frekari vitna við: hér er hvorki á ferðinni list né skáldskapur. Þau tvö eiga það nefnilega sameiginlegt samkvæmt einni skilgreiningunni að hafa engan annan tilgang en sig sjálf, þ.e., að vera list og/eða skáldskapur.</p>

        <p>Heimildirnar í hliðardálknum eru kveikja að ríminu og ekki túlkaðar samkvæmt þeirri söguskoðun sem var næstum einráð fram á níunda áratug 20. aldar.</p>
        <p>Með því vill höfundur axla ábyrgð á því að hafa í hálfa öld - frá 1964 - frætt börn um Íslandssögu eins og um hana var fjallað í Kennaraskólanum af afburðakennara sem var tvítugur þegar út kom Íslandssaga Jónasar Jónssonar, 1916.</p>

        <p>Axel Kristinsson segir í Hnignun, hvaða hnignun?, bls. 8: “Þegar þjóðin er búin að ákveða hvernig hún ætlar að minnast fortíðar sinnar þá er ekki auðvelt að telja henni hughvarf”.</p>
        <p>Hér er það þó reynt með því að leyfa fyrri alda þjóðhetjum að vera börn síns tíma, háðar mannlegum breyskleika og láta þær persónur Íslandssögunnar, sem fæddust erlendis, njóta sannmælis þrátt fyrir það. Þetta telst þó engin nýjung lengur þar eð sögurit og ævisögur sem út hafa komið þrjá til fjóra síðustu áratugi segja kost og löst á söguhetjum. (Sjá 1816 í 17. línu).</p>
        <p>Þörf þjóða fyrir hetjur mun æ fyrir hendi, en eftir 100 ára fullveldi ætti þörfin fyrir óvini að hafa dvínað enda vandfundnir.</p>
{/* 
        <h1>Um Annálsvert.is</h1>
        <p>Á þessari vefsíðu eru færð í rím eitt eða fleiri annálsverð tíðindi hvers árs frá siðaskiptum þar til Ísland gekk í NATO.</p>
        <p>Auk þess fær hver öld umfjöllun á lokaári sínu og tuttugasta öldin við 1950.</p>
        <p>Þó að síðuhöfundur ætli sér hvorki sæti á skáldabekk né sagnfræðinga, þá vonar hann að þegar rím hans fellur saman við annarra texta og myndir</p>
        <p>verði til ítarefni sem geri sögu tímabilsins auðlærðari og nái að tengjast fleiri greinum.</p>
        <p>Heimildirnar í hliðardálknum, sem eru kveikja að ríminu, eru ekki túlkaðar samkvæmt þeirri söguskoðun sem var einráð fram á níunda áratug 20. aldar.</p>
        <br /><br />
        <p>Kvæðin hafa þann kost með sér</p>
        <p>þau kennast betur og lærast ger,</p>
        <p>en málið laust úr minni fer. (Séra Einar Sigurðsson, Eydölum).</p>
        <br/><br/>
        <p>Með því vill höfundur axla ábyrgð á því að hafa í hálfa öld  - frá 1964 - frætt börn um Íslandssögu eins og um hana var fjallað í Kennaraskólanum af afburðakennara sem var tvítugur þegar út kom Íslandssaga Jónasar Jónssonar, 1916.</p>
        <p>Axel Kristinsson segir í Hnignun, hvaða hnignun?, bls. 8: “Þegar þjóðin er búin að ákveða hvernig hún ætlar að minnast fortíðar sinnar þá er ekki auðvelt að telja henni hughvarf”.</p>
        <p>Hér er það þó reynt með því að leyfa fyrri alda þjóðhetjum að vera börn síns tíma, háðar mannlegum breyskleika og láta þær persónur Íslandssögunnar, sem fæddust erlendis, njóta sannmælis þrátt fyrr það. Þetta telst þó engin nýjung lengur þar eð sögurit og ævisögur sem út hafa komið þrjá til fjóra síðustu áratugi segja kost og löst á söguhetjum. (Sjá 1816 í 17. línu).</p>
        <p>Þörf þjóða fyrir hetjur mun æ fyrir hendi, en eftir 100 ára fullveldi ætti þörfin fyrir óvini að hafa dvínað enda vandfundnir.</p> */}
        <p>Þetta mátti sjá eftir að "strákarnir okkar" sigruðu England. Í kjölfarið gátu fjölmiðlar hent gaman að 14 - 2 tapinu fyrir Dönum á hálfrar aldar ártíð þess og hér gert að lokaumfjöllun við1950.</p>

        <iframe title="Icelandic commentator goes crazy Island vs England 2-1 Euro 2016
" width="100%" height="350px" src="https://www.youtube.com/embed/opd-r_VPu3A" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <br /><br />
        <p>Slóðir framkalla sumar hverjar - ef smellt er á - myndir eða hljóðritanir sem eiga skylt við efnið. Samhengið getur þó verið langsótt og þurft hugmyndaflug til að ná tengingu. Gildir það líka um myndirnar neðan við textann og röð þeirra heldur ekki í samræmi við hann, og er það áskorun til að finna hvað á við hvað. Þrautin verður þyngri ef mynd er ekki stækkuð strax með því að smella á hana.</p>
        <p>Hljóðdæmin ættu að geta nýst til samþættingar sögu- og tónlistarkennslu.</p>
        <p>Til að finna ártal má ýmist nota svarta borðann með öldunum, eða skrá árið með tölustöfum á svörtu línuna á hvíta fletinum þar fyrir neðan, en þá virkar ekki að smella á "Næstu síðu" sem fer heldur ekki á milli alda. Á leitarlínuna má líka rita nöfn eða orð og setningar og birtast þá ár sem þau innihalda.</p>
        <p>Skáletruð ártöl tákna tilvísun eða samhengi.</p>
        <p>Gagnrýnar ábendingar eru vel þegnar og má skrifa á svæðið við hliðina á þessu: "Hafa samband".</p>
        <p>Þorgerður Gylfadóttir las efnið yfir og leiðrétti villur, en þess má vænta að einhverju verði aukið við eða breytt, t.d. eftir ábendingum notenda í dálkinum: "Hafa samband".</p>
        <p>Ingi Heiðmar Jónsson var ráðgjafi við kveðskap og fleira.</p>
        <p>Tölvuráðgjafi, Edgar Hjaltason.</p>
        <p>Heimasíðuna smíðaði CodeLab, Theódór Magnússon.</p>
        <p>Síðuhöfundur og ábyrgðarmaður er Glúmur Gylfason og raular flesta textana, en annarra flytjenda er getið á viðkomandi stað.</p>
    </Container>
)

export default About;