import React from 'react';
import './Pagination.scss';

const Pagination = ({ page, nextPage, previousPage }) => (
    <div className="pagination">
        <button className="prev" onClick={e => previousPage()}>
            Fyrri síða
        </button>
        <div className="curr">
            <h2>{page}</h2>
        </div>
        <button className="next" onClick={e => nextPage()}>
            Næsta síða
        </button>
    </div>
)

export default Pagination;